<template>
  <div class="accordion">
    <slot></slot>
  </div>
</template>

<script setup>
const model = defineModel()

watch(
  () => model.value,
  (newValue) => {
    const target = childrenRefs.value.find((child) => child.value == newValue)

    target.visible = !target.visible

    if (target.visible) {
      target.emits('activated')
    }

    if (!props.multiple) {
      const restOfItems = childrenRefs.value.filter((child) => child.value != newValue)
      restOfItems.forEach((child) => {
        child.visible = false
      })
    }
  },
)

const props = defineProps({
  current: [String, Number],
  multiple: Boolean,
  showArrow: Boolean,
  titleClass: String,
  contentClass: String,
})

const emit = defineEmits(['changed'])
const childrenRefs = ref([])

// Provide a method for children to register themselves
provide('registerItem', (item) => {
  childrenRefs.value.push(item)
})

const toggleVisibility = (target) => {
  if (model.value == target.value) {
    target.visible.value = !target.visible.value
    // if (target.visible.value)
    //   debugger;
    // target.emits('activated')
  }

  // if (target.visible.value)
  model.value = target.value
  // else
  // model.value = null;
  // target.visible.value = !target.visible.value

  // if (!props.multiple) {
  //   const restOfItems = childrenRefs.value.filter((child) => child.value !== target.value)
  //   restOfItems.forEach((child) => {
  //     child.visible = false
  //   })
  // }
}

provide('toggleVisibility', toggleVisibility)

onMounted(() => {
  childrenRefs.value.forEach((child) => {
    child.titleClass = props.titleClass
    child.contentClass = props.contentClass
    child.showArrow = props.showArrow
  })

  if (model.value !== undefined) {
    const active = childrenRefs.value.find((child) => {
      return child.value == model.value
    })

    if (active) {
      active.visible = true
    }
  }
})

// provide('current', props.current)
provide('showArrow', props.showArrow)
provide('titleClass', props.titleClass)
provide('contentClass', props.contentClass)
</script>

<style lang="scss">
.accordion {
}
</style>
